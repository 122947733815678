/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import api from '@/services/api';
import * as types from '@/store/mutation-types';
import { handleError } from '@/utils/utils';

const state = {
  plans: [],
};

const getters = {
  plans: (state) => state.plans,
};

const actions = {
  async getPlans({ commit, state }, options = { forceGet: false }) {
    if (state.plans.length && !options.forceGet) {
      return;
    }

    try {
      const response = await api.getPlans({
        page: 1,
        per_page: 100,
      });

      if (response.status === 200) {
        commit(types.SAVE_PLAN, response.data.data);
      }
    } catch (error) {
      handleError(error);
    }
  },
};

const mutations = {
  [types.SAVE_PLAN](state, plans) {
    state.plans = plans;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
