<template>
  <v-alert
    v-if="errorMessage"
    text
    type="error"
    icon="mdi-alert"
    transition="slide-y-transition"
    class="ma-3"
  >
    {{ errorMessage }}
    <div
      v-for="(error, index) in errorValidation"
      :key="index"
    >
      <small
        v-for="(e, i) in error"
        :key="i"
        class="my-0"
      >{{ e }}</small>
    </div>
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ErrorAlert',
  computed: {
    ...mapGetters(['errorMessage', 'errorValidation']),
  },
};
</script>
