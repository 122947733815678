/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import api from '@/services/api';
import * as types from '@/store/mutation-types';
import { handleError } from '@/utils/utils';

const state = {
  devices: [],
};

const getters = {
  devices: (state) => state.devices,
};

const actions = {
  async getDevices({ commit, state }, options = { forceGet: false }) {
    if (state.devices.length && !options.forceGet) {
      return;
    }

    try {
      const response = await api.getDevices({
        page: 1,
        per_page: 100,
      });

      if (response.status === 200) {
        commit(types.SAVE_DEVICE, response.data.data);
      }
    } catch (error) {
      handleError(error);
    }
  },
};

const mutations = {
  [types.SAVE_DEVICE](state, devices) {
    state.devices = devices;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
