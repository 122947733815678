/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import api from '@/services/api';
import * as types from '@/store/mutation-types';
import { handleError } from '@/utils/utils';

const defaultUser = {
  name: null,
  email: null,
  organization_id: null,
  is_organization_admin: false,
  is_superadmin: false,
  organization: null,
  unread_notifications_count: 0,
};

const state = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : defaultUser,
};

const getters = {
  user: (state) => state.user,
};

const actions = {
  async getProfile({ commit }) {
    try {
      const response = await api.getProfile();

      if (response.status === 200) {
        window.localStorage.setItem('user', JSON.stringify(response.data.data));
        commit(types.SAVE_USER, response.data.data);
      }
    } catch (error) {
      handleError(error);
    }
  },
};

const mutations = {
  [types.SAVE_USER](state, userData) {
    state.user = userData;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
