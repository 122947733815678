import Vue from 'vue';
import axios from 'axios';

const api = {
  // auth
  userLogin(payload) {
    return axios.post('/oauth/token', payload);
  },

  // profile
  getProfile() {
    return axios.get('/profile');
  },

  updateProfile(data) {
    return axios.put('/profile', data);
  },

  updatePassword(data) {
    return axios.put('/profile/password', data);
  },

  requestTelegram() {
    return axios.put('/profile/telegram');
  },

  revokeTelegram() {
    return axios.delete('/profile/telegram');
  },

  // organization
  getOrganizations(params) {
    return axios.get('/organizations', {
      params,
    });
  },

  getOrganizationDetail(orgId) {
    return axios.get(`/organizations/${orgId}`);
  },

  addOrganization(data) {
    return axios.post('/organizations', data);
  },

  updateOrganization({ id: orgId, ...data }) {
    return axios.put(`/organizations/${orgId}`, data);
  },

  deleteOrganization(orgId) {
    return axios.delete(`/organizations/${orgId}`);
  },

  // user
  getUsers(params) {
    return axios.get('/users', {
      params,
    });
  },

  addUser(data) {
    return axios.post('/users', data);
  },

  updateUser({ id: userId, ...data }) {
    return axios.put(`/users/${userId}`, data);
  },

  deleteUser(userId) {
    return axios.delete(`/users/${userId}`);
  },

  // plan
  getPlans(params) {
    return axios.get('/plans', {
      params,
    });
  },

  getPlanDetail(planId) {
    return axios.get(`/plans/${planId}`);
  },

  addPlan(data) {
    return axios.post('/plans', data);
  },

  updatePlan({ id: planId, ...data }) {
    return axios.put(`/plans/${planId}`, data);
  },

  deletePlan(planId) {
    return axios.delete(`/plans/${planId}`);
  },

  // subscription
  getSubscriptions(params) {
    return axios.get('/subscriptions', {
      params,
    });
  },

  getSubscriptionDetail(subsId) {
    return axios.get(`/subscriptions/${subsId}`);
  },

  addSubscription(data) {
    return axios.post('/subscriptions', data);
  },

  updateSubscription({ id: subsId, ...data }) {
    return axios.put(`/subscriptions/${subsId}`, data);
  },

  deleteSubscription(subsId) {
    return axios.delete(`/subscriptions/${subsId}`);
  },

  // devices
  getDevices(params) {
    return axios.get('/devices', {
      params,
    });
  },

  getDeviceDetail(devId) {
    return axios.get(`/devices/${devId}`);
  },

  addDevice(data) {
    return axios.post('/devices', data);
  },

  updateDevice({ id: devId, ...data }) {
    return axios.put(`/devices/${devId}`, data);
  },

  deleteDevice(devId) {
    return axios.delete(`/devices/${devId}`);
  },

  getDeviceStacks(devId, params) {
    return axios.get(`/devices/${devId}/stacks`, {
      params,
    });
  },

  sendDownlink(devId, downlink) {
    return axios.post(`/devices/${devId}/downlink`, downlink);
  },

  getScheduledCommands(deviceId) {
    return axios.get('/commands/schedules', {
      params: {
        device_id: deviceId,
      },
    });
  },

  sendScheduledCommand(downlink) {
    return axios.post('/commands/schedules', downlink);
  },

  deleteScheduledCommand(commandId) {
    return axios.delete(`/commands/schedules/${commandId}`);
  },

  // integration
  getIntegrations(params) {
    return axios.get('/integrations', {
      params,
    });
  },

  getDeviceProfiles(intId) {
    return axios.get(`/integrations/${intId}/device_profiles`);
  },

  getIntegrationDetail(intId) {
    return axios.get(`/integrations/${intId}`);
  },

  addIntegration(data) {
    return axios.post('/integrations', data);
  },

  updateIntegration({ id: intId, ...data }) {
    return axios.put(`/integrations/${intId}`, data);
  },

  deleteIntegration(intId) {
    return axios.delete(`/integrations/${intId}`);
  },

  // trigger
  getTriggers(params) {
    return axios.get('/triggers', {
      params,
    });
  },

  getTriggerDetail(intId) {
    return axios.get(`/triggers/${intId}`);
  },

  addTrigger(data) {
    return axios.post('/triggers', data);
  },

  updateTrigger({ id: intId, ...data }) {
    return axios.put(`/triggers/${intId}`, data);
  },

  deleteTrigger(intId) {
    return axios.delete(`/triggers/${intId}`);
  },

  // uplink
  getUplinks({ id: devId, ...params }) {
    return axios.get(`/devices/${devId}/uplinks`, {
      params,
    });
  },

  getStatusUplinks({ id: devId, ...params }) {
    return axios.get(`/devices/${devId}/status_uplinks`, {
      params,
    });
  },

  getPayloads({ id: devId, date, ...params }) {
    return axios.get(`/devices/${devId}/uplinks/${date}`, {
      params,
    });
  },

  // dashboard
  getDashboards(params) {
    return axios.get('/dashboards', {
      params,
    });
  },

  getDashboardDetail(intId) {
    return axios.get(`/dashboards/${intId}`);
  },

  addDashboard(data) {
    return axios.post('/dashboards', data);
  },

  updateDashboard({ id: intId, ...data }) {
    return axios.put(`/dashboards/${intId}`, data);
  },

  deleteDashboard(intId) {
    return axios.delete(`/dashboards/${intId}`);
  },

  // notification
  getNotifications(params) {
    return axios.get('/notifications', {
      params,
    });
  },

  readAllNotifications() {
    return axios.post('/notifications/read');
  },

  readNotification(notificationID) {
    return axios.get(`/notifications/${notificationID}`);
  },

  unreadNotification(notificationID) {
    return axios.put(`/notifications/${notificationID}/unread`);
  },

  // command
  getCommands(params) {
    return axios.get('/commands', {
      params,
    });
  },

  addCommand(data) {
    return axios.post('/commands', data);
  },

  updateCommand({ id: intId, ...data }) {
    return axios.put(`/commands/${intId}`, data);
  },

  deleteCommand(intId) {
    return axios.delete(`/commands/${intId}`);
  },

  // device commands
  getDeviceCommands(deviceId, params = {}) {
    return axios.get(`/devices/${deviceId}/commands`, {
      params,
    });
  },

  attachDeviceCommand(deviceId, data) {
    return axios.post(`/devices/${deviceId}/commands`, data);
  },

  detachDeviceCommand(deviceId, deviceCommandId) {
    return axios.delete(`/devices/${deviceId}/commands/${deviceCommandId}`);
  },

  // schedules
  getSchedules(params) {
    return axios.get('/schedules', {
      params,
    });
  },

  addSchedule(data) {
    return axios.post('/schedules', data);
  },

  updateSchedule({ id: scheduleId, ...data }) {
    return axios.put(`/schedules/${scheduleId}`, data);
  },

  deleteSchedule(scheduleId) {
    return axios.delete(`/schedules/${scheduleId}`);
  },
};

Vue.prototype.$api = api;

export default api;
