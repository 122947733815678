/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import api from '@/services/api';
import * as types from '@/store/mutation-types';
import router from '@/router';
import { handleError } from '@/utils/utils';

const state = {
  token: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access_token : null,
  isTokenSet: !!localStorage.getItem('token'),
};

const getters = {
  token: (state) => state.token,
  isTokenSet: (state) => state.isTokenSet,
};

const actions = {
  async userLogin({ commit, dispatch }, payload) {
    try {
      // Resets errors in store
      commit(types.ERROR, null);

      const response = await api.userLogin({
        grant_type: 'password',
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        username: payload.email,
        password: payload.password,
      });

      if (response.status === 200) {
        window.localStorage.setItem('token', JSON.stringify(response.data));

        commit(types.SAVE_TOKEN, response.data.access_token);

        // get user profile
        dispatch('getProfile');

        // redirect url
        if (payload.redirect) {
          router.push({
            path: payload.redirect,
          });
        } else {
          router.push({
            path: '/profile',
          });
        }
      }
    } catch (error) {
      handleError(error);
    }
  },

  userLogout({ commit }) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    commit(types.LOGOUT);
    router.push({
      path: '/login',
    });
  },
};

const mutations = {
  [types.SAVE_TOKEN](state, token) {
    state.token = token;
    state.isTokenSet = true;
  },
  [types.LOGOUT](state) {
    state.user = null;
    state.token = null;
    state.isTokenSet = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
