/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

// layout
const TheContainer = () => import(/* webpackChunkName: "TheContainer" */ '@/views/layout/TheContainer');

// dashboard
const DashboardList = () => import(/* webpackChunkName: "DashboardList" */ '@/views/dashboard/DashboardList');
const DashboardDetail = () => import(/* webpackChunkName: "DashboardDetail" */ '@/views/dashboard/DashboardDetail');

// Organizations
const OrganizationList = () => import(/* webpackChunkName: "OrganizationList" */ '@/views/organization/OrganizationList');
const OrganizationDetail = () => import(/* webpackChunkName: "OrganizationDetail" */ '@/views/organization/OrganizationDetail');

// Users
const UserList = () => import(/* webpackChunkName: "UserList" */ '@/views/user/UserList');

// Plans
const PlanList = () => import(/* webpackChunkName: "PlanList" */ '@/views/plan/PlanList');

// Subscriptions
const SubscriptionList = () => import(/* webpackChunkName: "SubscriptionList" */ '@/views/subscription/SubscriptionList');

// Devices
const DeviceList = () => import(/* webpackChunkName: "DeviceList" */ '@/views/device/DeviceList');
const DeviceDetail = () => import(/* webpackChunkName: "DeviceDetail" */ '@/views/device/DeviceDetail');

// Integrations
const IntegrationList = () => import(/* webpackChunkName: "IntegrationList" */ '@/views/integration/IntegrationList');

// Triggers
const TriggerList = () => import(/* webpackChunkName: "TriggerList" */ '@/views/trigger/TriggerList');

// Commands
const CommandList = () => import(/* webpackChunkName: "CommandList" */ '@/views/command/CommandList');

// Schedule
const ScheduleList = () => import(/* webpackChunkName: "ScheduleList" */ '@/views/schedule/ScheduleList');

// Profile
const UserProfile = () => import(/* webpackChunkName: "UserProfile" */ '@/views/profile/UserProfile');

// Notification
const NotificationList = () => import(/* webpackChunkName: "NotificationList" */ '@/views/notification/NotificationList');

// Page
const UserLogin = () => import(/* webpackChunkName: "UserLogin" */ '@/views/page/UserLogin');
const Page404 = () => import(/* webpackChunkName: "Page404" */ '@/views/page/Page404');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/profile',
    name: 'TheContainer',
    component: TheContainer,
    children: [
      {
        path: 'dashboards',
        redirect: '/dashboards', // fix vue-router warning
        name: 'Dashboards',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            component: DashboardList,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ':id',
            name: 'Dashboard Detail',
            component: DashboardDetail,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'organizations',
        redirect: '/organizations', // fix vue-router warning
        name: 'Organizations',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            component: OrganizationList,
            meta: {
              requiresAuth: true,
              requiresSuperadmin: true,
            },
          },
          {
            path: ':id',
            redirect: ':id/subscriptions', // auto redirect
          },
          {
            path: ':id/:tab',
            name: 'Organization Detail',
            component: OrganizationDetail,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'users',
        redirect: '/users', // fix vue-router warning
        name: 'Users',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            component: UserList,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'plans',
        redirect: '/plans', // fix vue-router warning
        name: 'Plans',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            component: PlanList,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'subscriptions',
        redirect: '/subscriptions', // fix vue-router warning
        name: 'Subscriptions',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            component: SubscriptionList,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'devices',
        redirect: '/devices', // fix vue-router warning
        name: 'Devices',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            component: DeviceList,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ':id',
            redirect: ':id/uplinks', // auto redirect
          },
          {
            path: ':id/:tab',
            name: 'Device Detail',
            component: DeviceDetail,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'integrations',
        redirect: '/integrations', // fix vue-router warning
        name: 'Integrations',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            component: IntegrationList,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'triggers',
        redirect: '/triggers', // fix vue-router warning
        name: 'Triggers',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            component: TriggerList,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'schedules',
        redirect: '/schedules', // fix vue-router warning
        name: 'Schedules',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            component: ScheduleList,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'commands',
        name: 'Commands',
        component: CommandList,
        meta: {
          requiresAuth: true,
          requiresSuperadmin: true,
        },
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: NotificationList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: UserProfile,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'User Login',
    component: UserLogin,
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: Page404,
  },
];

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const lastRouteObject = to.matched.slice().reverse().find((r) => r.name);
  if (lastRouteObject) {
    document.title = `${lastRouteObject.name} - Thingsnesia Console`;
  } else {
    document.title = 'Thingsnesia Console';
  }
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresSuperadmin = to.matched.some((record) => record.meta.requiresSuperadmin);
  const { isTokenSet, user } = store.getters;

  if (isTokenSet && requiresAuth && requiresSuperadmin) {
    if (!user.is_superadmin) {
      // if non-superadmin go to organizations,
      // redirect to his organization (if any),
      // otherwise go to profile
      if (to.path === '/organizations' && user.organization && user.organization.id) {
        return next({ path: `/organizations/${user.organization.id}` });
      }
      return next({ path: '/profile' });
    }
    next();
  }

  if (requiresAuth && !isTokenSet) {
    return next({
      path: '/login',
      query: {
        redirect: to.fullPath,
      },
    });
  }

  if (isTokenSet && to.fullPath === '/login') {
    return next({ path: '/profile' });
  }

  return next();
});

export default router;
