/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import api from '@/services/api';
import * as types from '@/store/mutation-types';
import { handleError } from '@/utils/utils';

const state = {
  organizations: [],
};

const getters = {
  organizations: (state) => state.organizations,
};

const actions = {
  async getOrganizations({ commit, state }, options = { forceGet: false }) {
    if (state.organizations.length && !options.forceGet) {
      return;
    }

    try {
      const response = await api.getOrganizations({
        page: 1,
        per_page: 100,
      });

      if (response.status === 200) {
        commit(types.SAVE_ORGANIZATION, response.data.data);
      }
    } catch (error) {
      handleError(error);
    }
  },
};

const mutations = {
  [types.SAVE_ORGANIZATION](state, organizations) {
    state.organizations = organizations;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
