/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import * as types from '@/store/mutation-types';

const state = {
  dashboardEditMode: false,
};

const getters = {
//   dashboardEditMode: (state) => state.dashboardEditMode,
};

const actions = {
};

const mutations = {
  [types.TOGGLE_EDIT_MODE](state, dashboardEditMode) {
    state.dashboardEditMode = dashboardEditMode;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
