<template>
  <div>
    <transition name="fade">
      <router-view />
    </transition>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: 'App',
  components: { ConfirmDialog },

  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
  },
};
</script>

<style lang="scss">
@import "assets/scss/custom";

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
