import Vue from 'vue';
import {
  parseISO,
  format,
  formatDistanceStrict,
} from 'date-fns';

Vue.filter('formatDate', (value) => {
  // format date only
  // e.g. 2 september 2019 - 14:44
  if (!value) return '';
  return format(parseISO(value), 'EEEE, dd MMM yyyy');
});

Vue.filter('formatDateTime', (value) => {
  // format date to date and time
  // e.g. 2 september 2019 - 14:44
  if (!value) return '';
  return format(parseISO(value), 'dd MMM yyyy - HH:mm');
});

Vue.filter('formatDateFromNow', (value) => {
  // format date to relative date
  // e.g. 2 days ago
  if (!value) return '';
  return formatDistanceStrict(parseISO(value), new Date(), {
    roundingMethod: 'floor',
    addSuffix: true,
  });
});

Vue.filter('parsePayloadKey', (payloadKey) => {
  if (!payloadKey) return {};
  switch (payloadKey.toString().toLowerCase()) {
    case 'air_hum':
    case 'air_humidity':
      return {
        name: 'Air Humidity',
        unit: '%',
        icon: 'wi-barometer',
        description: 'The amount of water vapor present in air',
      };
    case 'air_pressure':
      return {
        name: 'Air Pressure',
        unit: 'hPa',
        icon: 'wi-barometer',
        description: 'The force exerted on a surface by the air above it as gravity pulls it to Earth',
      };
    case 'air_temp':
    case 'air_temperature':
      return {
        name: 'Air Temp.',
        unit: '°C',
        icon: 'wi-thermometer',
        description: 'The temperature of the air',
      };
    case 'altitude':
      return {
        name: 'Altitude',
        unit: 'meter',
        icon: 'fa-compass',
      };
    case 'avg_corrected_wind_direction':
      return {
        name: 'Avg. Corrected Wind Direction',
        unit: '°',
        description: 'Average direction of wind corrected by compass heading and GPS',
      };
    case 'avg_corrected_wind_speed':
      return {
        name: 'Avg. Corrected Wind Speed',
        unit: 'm/s',
        description: 'Averaged speed of wind corrected by compass heading and GPS',
      };
    case 'avg_wind_speed':
      return {
        name: 'Avg. Wind Speed',
        unit: 'm/s',
        icon: 'wi-strong-wind',
        description: 'Averaged speed of wind',
      };
    case 'batt':
      return {
        name: 'Battery',
        unit: 'Volt',
        icon: 'fa-battery-full',
      };
    case 'batt_voltage':
      return {
        name: 'Batt. Voltage',
        unit: 'Volt',
        icon: 'fa-battery-full',
        description: "Voltage of the node's battery",
      };
    case 'battery_node_temp':
      return {
        name: 'Battery Node Temperature',
        unit: '°C',
        icon: 'fa-thermometer-empty',
      };
    case 'battery_plts_temp':
      return {
        name: 'Battery WSC Temperature',
        unit: '°C',
        icon: 'fa-thermometer-empty',
      };
    case 'capacitor_duration':
      return {
        name: 'Capacitor Duration',
        unit: 'Seconds',
        description: 'The charging duration of the capacitor used to change the state of the valve',
      };
    case 'capacitor_voltage':
      return {
        name: 'Capacitor Voltage',
        unit: 'Volt',
        description: 'The voltage of capacitor used to activate the valve',
      };
    case 'cardinal_direction_wind':
      return {
        name: 'Cardinal Direction of Wind',
        description: 'The cardinal direction of the wind based on average corrected wind direction (North, South, West, East)',
      };
    case 'cb1':
      return {
        name: 'SWT 1',
        unit: 'cbar',
        icon: 'wi-barometer',
        description: 'Soil water tension indicates the effort required by root systems to extract water from the soil. The higher the value, the higher the effort.',
      };
    case 'cb2':
      return {
        name: 'SWT 2',
        unit: 'cbar',
        icon: 'wi-barometer',
        description: 'Soil water tension indicates the effort required by root systems to extract water from the soil. The higher the value, the higher the effort.',
      };
    case 'charge':
      return {
        name: 'Charge',
        unit: 'mA',
        icon: 'fa-plug',
      };
    case 'charging_duration':
      return {
        name: 'Charging Duration',
        unit: 'seconds',
        icon: 'fa-plug',
        description: 'The charging duration of the capacitor used to activate the valve',
      };
    case 'compass_heading':
      return {
        name: 'Compass Heading',
        unit: '°',
        description: 'Compass heading direction',
      };
    case 'corrected_wind_direction':
      return {
        name: 'Corrected Wind Direction',
        unit: '°',
        description: 'Direction of wind corrected by compass heading and GPS',
      };
    case 'counter':
      return {
        name: 'Counter',
        unit: 'times',
        description: 'Uplink counter',
      };
    case 'cumulative_counter':
      return {
        name: 'Cumulative Counter',
        unit: 'times',
        description: 'Defines how many contacts occured when water flow in the irrigation pipe',
      };
    case 'cvolt':
      return {
        name: 'Cvolt',
        unit: 'Volt',
        icon: 'fa-bolt',
      };
    case 'delta_evaporation':
      return {
        name: 'Delta Evaporation',
        unit: 'mm',
        icon: 'wi-fog',
        description: 'The amount of water that has evaporated since the last transmission',
      };
    case 'dewpoint':
      return {
        name: 'Dew Point',
        unit: '°C',
        description: 'The temperature at which air is saturated with water vapor',
      };
    case 'dielectric_permittivity':
      return {
        name: 'DP',
        unit: '',
        icon: 'wi-flood',
        description: 'Dielectric Permittivity - The ability of a substance to hold an electrical charge',
      };
    case 'distance':
      return {
        name: 'Distance',
        unit: 'meter',
        description: 'Distance of the sensor to the object (water surface)',
      };
    case 'dp':
      return {
        name: 'DP',
        unit: '',
        icon: 'wi-flood',
        description: 'Dielectric Permittivity - The ability of a substance to hold an electrical charge',
      };
    case 'ec':
    case 'electrical_conductivity':
      return {
        name: 'EC',
        unit: 'dS/m',
        icon: 'wi-earthquake',
        description: "Electrical conductivity - A material's ability to carry electrical current",
      };
    case 'evaporation':
      return {
        name: 'Evaporation',
        unit: 'mm',
        icon: 'wi-fog',
        description: 'Vaporization that occurs on the surface of a liquid as it changes into the gas phase',
      };
    case 'evaporation_n1':
      return {
        name: 'Evaporation N-1',
        unit: 'mm',
        icon: 'wi-fog',
        description: 'Vaporization that occurs on the surface of a liquid as it changes into the gas phase 30 minutes ago',
      };
    case 'evaporation_n2':
      return {
        name: 'Evaporation N-2',
        unit: 'mm',
        icon: 'wi-fog',
        description: 'Vaporization that occurs on the surface of a liquid as it changes into the gas phase 60 minutes ago',
      };
    case 'evaporation_pan_level':
      return {
        name: 'Evaporation Pan Level',
        unit: 'mm',
        icon: 'wi-fog',
        description: 'Height of the empty volume of the pan (0 mm: Full of water | 75 mm : Pan empty)',
      };
    case 'failsafe_period':
      return {
        name: 'Failsafe Period',
        unit: 'minute',
        description: 'If the value is not zero, this period defines the duration of valve will be forced to close after the valve is opened',
      };
    case 'frequency':
      return {
        name: 'Frequency',
        unit: 'MHz',
        icon: 'fa-bullseye',
        description: 'Frequency used in an uplink',
      };
    case 'fw_version':
      return { name: 'Firmware Version', description: "Node's current firmware version" };
    case 'gmx_status':
      return { name: 'GMX Status' };
    case 'gps_avg_corrected_speed':
      return { name: 'GPS Avg. Corrected Speed' };
    case 'gps_satellite':
      return {
        name: 'GPS Satellite',
        description: 'The number of available GPS satellites',
      };
    case 'humidity':
      return {
        name: 'Humidity',
        unit: '%',
        icon: 'wi-humidity',
        description: 'The concentration of water vapor in the air',
      };
    case 'i_a':
    case 'i_b':
    case 'i_c':
      return {
        name: `Current ${fallbackValue.name}`,
        unit: 'A',
        icon: 'fa-bolt',
      };
    case 'i_charge_current':
      return {
        name: 'Charging Current',
        unit: 'mA',
        icon: 'fa-plug',
        description: 'Amount of current charged to the battery at the time',
      };
    case 'i_charge_voltage':
      return {
        name: 'Charge Voltage',
        unit: 'Volt',
        icon: 'fa-plug',
        description: 'The charging voltage that goes into the device',
      };
    case 'icharge':
      return {
        name: 'Charge Current',
        unit: 'Ampere',
        icon: 'fa-plug',
      };
    case 'is_status_uplink':
      return { name: 'Is Status Uplink', description: 'Status request uplink flag' };
    case 'kwa':
    case 'kwb':
    case 'kwc':
    case 'kwh':
      return {
        name: 'kWh',
        unit: 'kWh',
        icon: 'fa-superpowers',
      };
    case 'kwt':
      return {
        name: `Power ${fallbackValue.name}`,
        unit: 'kW',
        icon: 'fa-bolt',
      };
    case 'last_rssi':
      return {
        name: 'Last Downlink RSSI',
        unit: 'dBm',
        description: 'Last downlink RSSI received by node',
      };
    case 'last_snr':
      return {
        name: 'Last Downlink SNR',
        unit: 'dB',
        description: 'Last downlink SNR received by node',
      };
    case 'last_txpow_dbm':
      return {
        name: 'Last Uplink Tx Power',
        unit: 'dBm',
        description: 'Last uplink Tx Power used by node',
      };
    case 'latitude':
      return {
        name: 'Latitude',
        unit: '°',
        icon: 'fa-compass',
      };
    case 'longitude':
      return {
        name: 'Longitude',
        unit: '°',
        icon: 'fa-compass',
      };
    case 'max_wind_speed':
      return {
        name: 'Max Wind Speed',
        unit: 'm/s',
        icon: 'wi-strong-wind',
        description: 'Maximum speed of air moving past a certain point',
      };
    case 'mcu_temp':
      return {
        name: 'MCU Temp.',
        unit: '°C',
        icon: 'wi-thermometer',
        description: "Node's current MCU internal temperature",
      };
    case 'moisture_1':
      return {
        name: 'Moisture 1',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Moisture at 10 cm',
      };
    case 'moisture_10_cm':
      return {
        name: 'Moisture at 10 cm',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Soil moisture measured at a depth of 10 cm',
      };
    case 'moisture_2':
      return {
        name: 'Moisture 2',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Moisture at 20 cm',
      };
    case 'moisture_20_cm':
      return {
        name: 'Moisture at 20 cm',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Soil moisture measured at a depth of 20 cm',
      };
    case 'moisture_3':
      return {
        name: 'Moisture 3',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Moisture at 40 cm',
      };
    case 'moisture_4':
      return {
        name: 'Moisture 4',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Moisture at 60 cm',
      };
    case 'moisture_40_cm':
      return {
        name: 'Moisture at 40 cm',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Soil moisture measured at a depth of 40 cm',
      };
    case 'moisture_60_cm':
      return {
        name: 'Moisture at 60 cm',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Soil moisture measured at a depth of 60 cm',
      };
    case 'pH':
      return {
        name: 'PH',
        unit: '',
        icon: 'wi-thermometer-internal',
      };
    case 'packet_type_switch_status':
      return { name: 'Confirmed Message State', description: "If the value is zero, node message state is unconfirmed. If not, node message state is confirmed and the value defines how many uplink trials must be done when node don't receive uplink confirmation." };
    case 'pf_a':
    case 'pf_b':
    case 'pf_c':
      return {
        name: `Power Factor ${fallbackValue.name}`,
        unit: '',
        icon: 'fa-bolt',
      };
    case 'positioning_status':
      return {
        name: 'Positioning Status',
        unit: '-',
        description: 'GPS positioning information status (0: Positioning info has not been acquired | 1: Positioning info has been acquired)',
      };
    case 'precip_intensity':
      return { name: 'Precip. Intensity' };
    case 'precip_total':
      return {
        name: 'Precip. Total',
        unit: 'mm',
        icon: 'wi-sleet',
        description: 'An accumulated rainfall within a day (1 mm means 1 liter of rainfall in 1 m2 of soil)',
      };
    case 'precipintensity':
    case 'precipitation_intensity':
      return {
        name: 'Precipitation Intensity',
        unit: 'mm/30minutes',
        icon: 'wi-sleet',
        description: 'The rainfall per unit time',
      };
    case 'precipitation_intensity_hour':
      return {
        name: 'Precip. Intensity',
        unit: 'mm/H',
        icon: 'wi-sleet',
        description: 'The ratio of the total amount of rain (rainfall depth) falling during a given period to the duration of the period',
      };
    case 'precipitation_total':
      return {
        name: 'Precip. Total',
        unit: 'mm',
        icon: 'wi-sleet',
        description: 'An accumulated rainfall within a day (1 mm means 1 liter of rainfall in 1 m2 of soil)',
      };
    case 'preciptotal':
      return {
        name: 'Precipitation Total',
        unit: 'mm/day',
        icon: 'wi-sleet',
        description: 'The rainfall during a certain time',
      };
    case 'pressure':
      return {
        name: 'Water Pressure',
        unit: 'bar',
        icon: 'wi-barometer',
        description: 'Water pressure in irrigation pipe',
      };
    case 'pressure_1':
      return {
        name: 'Water Pressure 1',
        unit: 'bar',
        icon: 'wi-barometer',
        description: 'Water pressure in irrigation pipe',
      };
    case 'pressure_2':
      return {
        name: 'Water Pressure 2',
        unit: 'bar',
        icon: 'wi-barometer',
        description: 'Water pressure in irrigation pipe',
      };
    case 'rainfall_accumulative':
      return {
        name: 'Rainfall Accumulative',
        unit: 'mm',
        icon: 'wi-sleet',
        description: 'An accumulated rainfall within a day (1 mm means 1 liter of rainfall in 1 m2 of soil)',
      };
    case 'rainfall_intensity':
      return {
        name: 'Rainfall Intensity',
        unit: 'mm/H',
        icon: 'wi-sleet',
        description: 'The ratio of the total amount of rain (rainfall depth) falling during a given period to the duration of the period',
      };
    case 'read_retry':
      return {
        name: 'Read Retry',
        unit: '',
        icon: 'fa-repeat',
      };
    case 'real_wind_direction':
      return {
        name: 'Real Wind Direction',
        unit: '°',
        icon: 'wi-wind wi-towards-nne',
        description: 'Direction of wind corrected by compass heading',
      };
    case 'real_wind_speed':
      return {
        name: 'Real Wind Speed',
        unit: 'm/s',
        description: 'Speed of wind corrected by compass heading and GPS',
      };
    case 'redox':
      return {
        name: 'Redox',
        unit: '',
        icon: 'fa-flask',
      };
    case 'relative_humidity':
      return {
        name: 'Relative Humidity',
        unit: '%',
        description: 'The amount of water vapor present in air',
      };
    case 'resistance1':
      return {
        name: 'WM Res. 1',
        unit: 'ohm',
        description: 'Watermark sensor resistance value',
      };
    case 'resistance2':
      return {
        name: 'WM Res. 2',
        unit: 'ohm',
        description: 'Watermark sensor resistance value',
      };
    case 'rssi':
      return {
        name: 'RSSI',
        unit: 'dBm',
        description: 'Received Signal Strength Indicator - A measurement of the power present in a received radio signal',
      };
    case 'rtd_temp':
      return {
        name: 'RTD Temperature',
        unit: '°C',
        icon: 'wi-thermometer',
      };
    case 'safemode_status':
      return {
        name: 'Safemode Status',
        description: 'If safemode is active (the value is 1), node will conserve the battery until fully charged',
      };
    case 'send_trials':
      return {
        name: 'Send Trials',
        unit: '-',
        description: 'Indicates the number of attempts at sending the correct data by the node. For debugging purposes only.',
      };
    case 'sensor1_current':
      return {
        name: 'Sensor 1 Current',
        unit: 'mA',
        description: 'Value of sensor input current on channel-1 converted from ADC by MCU [For debugging purposes only]',
      };
    case 'sensor2_current':
      return {
        name: 'Sensor 2 Current',
        unit: 'mA',
        description: 'Value of sensor input current on channel-2 converted from ADC by MCU [For debugging purposes only]',
      };
    case 'sensor_current':
      return {
        name: 'Sensor Current',
        unit: 'mA',
        description: 'Value of sensor input current converted from ADC by MCU [For debugging purposes only]',
      };
    case 'sensor_hex_data_debug':
      return {
        name: 'Sensor Hex Data Debug',
        unit: '-',
        description: 'Raw data from RK900-10 Weather Station sensor in hex form. For debugging purposes only.',
      };
    case 'sensor_read_trials':
      return {
        name: 'Sensor Read Trials',
        unit: '-',
        description: 'Indicates the number of reading attempts made by the sensor. For debugging purposes only.',
      };
    case 'sensor_status':
      return {
        name: 'Sensor Status',
        unit: '',
        description: "The sensor status code based on sensor's datasheet",
      };
    case 'sensor_status_description':
      return {
        name: 'Sensor Status Description',
        unit: '',
        description: "The sensor status description based on sensor's datasheet",
      };
    case 'sensor_supply_voltage':
      return {
        name: 'Sensor Supply Voltage',
        unit: 'Volt',
        description: 'DC Supply voltage measured by the sensor',
      };
    case 'sludge_level':
      return {
        name: 'Sludge Level',
        unit: 'mg/l',
        icon: 'wi-thermometer-internal',
      };
    case 'snr':
      return {
        name: 'SNR',
        unit: 'dB',
        description: 'Signal-to-noise Ratio - A ratio of signal power to the noise power',
      };
    case 'soil_dp':
      return {
        name: 'Soil Dielectric Permittivity',
        unit: '',
        icon: 'wi-flood',
      };
    case 'soil_ec':
    case 'soil_electrical_conductivity':
      return {
        name: 'Soil Electrical Conductivity',
        unit: 'mS/cm',
        icon: 'wi-earthquake',
        description: 'An indirect measurement that correlates very well with several soil physical and chemical properties',
      };
    case 'soil_moisture':
      return {
        name: 'Soil Moisture',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Water content of the soil',
      };
    case 'soil_moisture_10cm':
      return {
        name: 'Soil Moisture 10cm',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Water content of the soil depth 10 cm',
      };
    case 'soil_moisture_20cm':
      return {
        name: 'Soil Moisture 20cm',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Water content of the soil depth 20 cm',
      };
    case 'soil_moisture_40cm':
      return {
        name: 'Soil Moisture 40cm',
        unit: '%',
        icon: 'wi-humidity',
        description: 'Water content of the soil depth 40 cm',
      };
    case 'soil_temp':
      return {
        name: 'Soil Temp.',
        unit: '°C',
        icon: 'wi-thermometer',
        description: 'Temperature of soil',
      };
    case 'soil_temp_1':
      return {
        name: 'Soil Temp. 1',
        unit: '°C',
        icon: 'wi-humidity',
        description: 'Soil Temp. at 10 cm',
      };
    case 'soil_temp_10_cm':
      return {
        name: 'Soil Temp. at 10 cm',
        unit: '°C',
        icon: 'wi-humidity',
        description: 'Soil temperature measured at a depth of 10 cm',
      };
    case 'soil_temp_2':
      return {
        name: 'Soil Temp. 2',
        unit: '°C',
        icon: 'wi-humidity',
        description: 'Soil Temp. at 20 cm',
      };
    case 'soil_temp_20_cm':
      return {
        name: 'Soil Temp. at 20 cm',
        unit: '°C',
        icon: 'wi-humidity',
        description: 'Soil temperature measured at a depth of 20 cm',
      };
    case 'soil_temp_3':
      return {
        name: 'Soil Temp. 3',
        unit: '°C',
        icon: 'wi-humidity',
        description: 'Soil Temp. at 40 cm',
      };
    case 'soil_temp_4':
      return {
        name: 'Soil Temp. 4',
        unit: '°C',
        icon: 'wi-humidity',
        description: 'Soil Temp. at 60 cm',
      };
    case 'soil_temp_40_cm':
      return {
        name: 'Soil Temp. at 40 cm',
        unit: '°C',
        icon: 'wi-humidity',
        description: 'Soil temperature measured at a depth of 40 cm',
      };
    case 'soil_temp_60_cm':
      return {
        name: 'Soil Temp. at 60 cm',
        unit: '°C',
        icon: 'wi-humidity',
        description: 'Soil temperature measured at a depth of 60 cm',
      };
    case 'soil_temperature':
      return {
        name: 'Soil Temperature',
        unit: '°C',
        icon: 'wi-thermometer',
        description: 'Temperature of the soil',
      };
    case 'soil_temperature_10cm':
      return {
        name: 'Soil Temperature 10cm',
        unit: '°C',
        icon: 'wi-thermometer',
        description: 'Temperature of the soil depth 10 cm',
      };
    case 'soil_temperature_20cm':
      return {
        name: 'Soil Temperature 20cm',
        unit: '°C',
        icon: 'wi-thermometer',
        description: 'Temperature of the soil depth 20 cm',
      };
    case 'soil_temperature_40cm':
      return {
        name: 'Soil Temperature 40cm',
        unit: '°C',
        icon: 'wi-thermometer',
        description: 'Temperature of the soil depth 40 cm',
      };
    case 'soil_vmc':
      return {
        name: 'Soil Volumetric Water Content',
        unit: '%',
        icon: 'wi-raindrop',
      };
    case 'soil_water_tension_1':
      return {
        name: 'SWT 1',
        unit: 'cbar',
        icon: 'wi-barometer',
        description: 'Soil water tension indicates the effort required by root systems to extract water from the soil. The higher the value, the higher the effort.',
      };
    case 'soil_water_tension_2':
      return {
        name: 'SWT 2',
        unit: 'cbar',
        icon: 'wi-barometer',
        description: 'Soil water tension indicates the effort required by root systems to extract water from the soil. The higher the value, the higher the effort.',
      };
    case 'soil_wt':
      return {
        name: 'Soil Water Tension',
        unit: 'cbar',
        icon: 'wi-barometer',
      };
    case 'soil_wt1':
      return {
        name: 'Soil Water Tension 1',
        unit: 'cbar',
        icon: 'wi-barometer',
      };
    case 'soil_wt2':
      return {
        name: 'Soil Water Tension 2',
        unit: 'cbar',
        icon: 'wi-barometer',
      };
    case 'solar_par':
      return {
        name: 'Photosynthetically Active Radiation',
        unit: 'µmol m^-2 s^-1',
        icon: 'wi-hot',
        description: 'Spectral range (wave band) of solar radiation from 400 to 700 nanometers that photosynthetic organisms are able to use in the process of photosynthesis',
      };
    case 'solar_par_n1':
      return {
        name: 'Photosynthetically Active Radiation N-1',
        unit: 'µmol m^-2 s^-1',
        icon: 'wi-hot',
        description: 'Spectral range (wave band) of solar radiation from 400 to 700 nanometers that photosynthetic organisms are able to use in the process of photosynthesis 30 minutes ago',
      };
    case 'solar_par_n2':
      return {
        name: 'Photosynthetically Active Radiation N-2',
        unit: 'µmol m^-2 s^-1',
        icon: 'wi-hot',
        description: 'Spectral range (wave band) of solar radiation from 400 to 700 nanometers that photosynthetic organisms are able to use in the process of photosynthesis 60 minutes ago',
      };
    case 'solar_rad':
    case 'solar_radiation':
      return {
        name: 'Solar Radiation',
        unit: 'W/m^2',
        icon: 'wi-hot',
        description: 'The radiant (electromagnetic) energy from the sun',
      };
    case 'solar_uv':
      return {
        name: 'Ultraviolet Sensor',
        unit: 'UVR',
        icon: 'wi-day-sunny',
      };
    case 'solar_voltage':
      return {
        name: 'Solar Panel Voltage',
        unit: 'Volt',
        icon: 'fa-bolt',
      };
    case 'sun_hour':
    case 'sunshine_hour':
    case 'sunshine_hours':
      return {
        name: 'Sunshine Hours',
        unit: 'Hours',
        icon: 'wi-day-sunny',
        description: 'The period of time, in hours, within a day that the measured irradiance exceeds 120 W/m²',
      };
    case 'supply_volt':
    case 'supply_voltage':
      return {
        name: 'Sensor Supply Voltage',
        unit: 'Volt',
        icon: 'fa-bolt',
      };
    case 'temp':
      return {
        name: 'Temperature',
        unit: '°C',
        icon: 'wi-thermometer',
      };
    case 'thd_va':
    case 'thd_vb':
    case 'thd_vc':
      return {
        name: fallbackValue.name,
        unit: '%',
        icon: 'fa-percent',
      };
    case 'turbidity':
      return {
        name: 'Turbidity',
        unit: 'NTU',
        description: 'An optical measurement that indicates the presence of suspended particles. The higher the value, the higher concentration of suspended solids',
      };
    case 'uplink_period':
      return {
        name: 'Uplink Period',
        unit: 'minute(s)',
        description: "Node's current uplink period",
      };
    case 'v_a':
    case 'v_ab':
    case 'v_b':
    case 'v_bc':
    case 'v_c':
    case 'v_ca':
      return {
        name: `Voltage ${fallbackValue.name}`,
        unit: 'V',
        icon: 'fa-bolt',
      };
    case 'vref':
      return {
        name: 'Voltage Ref.',
        unit: 'Volt',
        icon: 'fa-bolt',
        description: 'Voltage reference for sensor reading [For debugging purposes only]',
      };
    case 'v_solar':
      return {
        name: 'Solar Voltage',
        unit: 'Volt',
        icon: 'fa-bolt',
        description: 'Solar panel voltage',
      };
    case 'v_step_up':
      return {
        name: 'Step Up Voltage',
        unit: 'Volt',
        icon: 'fa-bolt',
        description: 'Sensor supply voltage',
      };
    case 'valve':
      return {
        name: 'Valve State',
        unit: '',
        icon: 'fa-shower',
        description: 'Defines the valve state \n (0: OFF | 1: ON)',
      };
    case 'varh':
      return {
        name: 'kVARh',
        unit: '',
        icon: 'fa-bolt',
      };
    case 'vbat':
      return {
        name: 'Batt. Voltage',
        unit: 'volt',
        description: 'Node current battery voltage',
      };
    case 'vmc':
      return {
        name: 'Volumetric Water Content',
        unit: '%',
        icon: 'wi-raindrop',
        description: 'Volumetric water content',
      };
    case 'volumetric_water_content':
    case 'vwc':
      return {
        name: 'VWC',
        unit: '%',
        description: 'Volumetric water content - The ratio of the volume of water (liter) to the 1 m3 volume of soil',
      };
    case 'water_flow':
      return {
        name: 'Water Flow',
        unit: 'Liter',
        icon: 'wi-raindrops',
      };
    case 'water_level':
      return {
        name: 'Water Level',
        unit: 'meter',
        description: 'Level of water',
      };
    case 'water_temp':
      return {
        name: 'Water Temperature',
        unit: '°C',
        icon: 'wi-thermometer',
      };
    case 'water_vol':
      return {
        name: 'Water Volume',
        unit: 'Liter',
        icon: 'wi-raindrop',
      };
    case 'wh':
      return { name: fallbackValue.name };
    case 'wind_cardinal_direction':
      return {
        name: 'Wind Cardinal Direction',
        unit: '-',
        description: 'The cardinal direction of the wind based on average corrected wind direction (North, South, West, East)',
      };
    case 'wind_dir':
    case 'wind_direction':
      return {
        name: 'Wind Direction',
        unit: '°',
        icon: 'wi-wind wi-towards-nne',
        description: 'The direction from which the wind is blowing',
      };
    case 'wind_sensor_status':
      return {
        name: 'Wind Sensor Status',
        description: "The wind sensor status code based on sensor's datasheet",
      };
    case 'wind_sensor_status_description':
      return {
        name: 'Wind Sensor Status Description',
        description: "The wind sensor status description based on sensor's datasheet",
      };
    case 'wind_speed':
      return {
        name: 'Wind Speed',
        unit: 'm/s',
        icon: 'wi-strong-wind',
        description: 'Speed of wind',
      };
    case 'wind_status':
      return {
        name: 'Wind Sensor Status',
        description: "The wind sensor status code based on sensor's datasheet",
      };
    case 'wind_status_description':
      return {
        name: 'Wind Sensor Status Description',
        description: "The wind sensor status description based on sensor's datasheet",
      };
    case 'WM_Res_1':
      return {
        name: 'WM Res. 1',
        unit: 'ohm',
        description: 'Watermark sensor resistance value',
      };
    case 'WM_Res_2':
      return {
        name: 'WM Res. 2',
        unit: 'ohm',
        description: 'Watermark sensor resistance value',
      };
    case 'WM_SWT_1':
      return {
        name: 'WM SWT 1',
        unit: 'cbar',
        icon: 'wi-barometer',
      };
    case 'WM_SWT_2':
      return {
        name: 'WM SWT 2',
        unit: 'cbar',
        icon: 'wi-barometer',
      };
    default:
      // Generate Default Value
      return {
        name: payloadKey,
        unit: '',
        icon: 'fa-star',
      };
  }
});
