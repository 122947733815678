import Vue from 'vue';
import ErrorAlert from '@/components/ErrorAlert.vue';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import './plugins/axios';
import './plugins/toast';
import './plugins/datetime-picker';
import App from './App.vue';
import './utils/filters';

// get page title based on route name
Vue.prototype.$pageTitle = function () {
  return this.$route.matched.filter((r) => r.name).pop().name;
};

// show error message from vuex store
Vue.component('ErrorAlert', ErrorAlert);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
