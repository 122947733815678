/* eslint-disable import/prefer-default-export */
// auth
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const LOGOUT = 'LOGOUT';
export const ERROR = 'ERROR';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const SAVE_USER = 'SAVE_USER';
export const SAVE_ORGANIZATION = 'SAVE_ORGANIZATION';
export const SAVE_PLAN = 'SAVE_PLAN';
export const SAVE_DEVICE = 'SAVE_DEVICE';
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
