import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

const mq = window.matchMedia('(prefers-color-scheme: dark)');

// vuetify options
const opts = {
  theme: {
    dark: mq.matches,
    themes: {
      dark: {
        primary: colors.blue.darken1,
        success: colors.green.darken2,
        warning: colors.amber.darken3,
        error: colors.red.darken3,
        thingsnesia_base: colors.green.darken3,
        background_color: colors.grey.darken3,
      },
      light: {
        background_color: colors.grey.lighten3,
        thingsnesia_base: colors.green.darken2,
      },
    },
  },
};

const vuetify = new Vuetify(opts);

// listen for browser dark theme
mq.addEventListener('change', (e) => {
  vuetify.framework.theme.dark = e.matches;
});

export default vuetify;
