/* eslint-disable no-param-reassign */
import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';


axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';


// update token every request
axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${store.getters.token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

// handle http status response
axios.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    // logout user
    Promise.resolve(store.dispatch('userLogout'));
  } else if (error.response.status === 403) {
    // get new profile data
    router.push({ path: '/profile' });
  }
  throw error;
});


// eslint-disable-next-line no-shadow
Plugin.install = (Vue) => {
  Vue.axios = axios;
  // window.axios = axios; // cause error in any page, when combine with laravel echo
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      },
    },
    $axios: {
      get() {
        return axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
