/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import * as types from '@/store/mutation-types';
import modules from './modules';
import packageJson from '../../package.json';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ...modules,
  },

  state: {
    errorMessage: null,
    errorValidation: null,
    appVersion: packageJson.version,
  },

  getters: {
    errorMessage: (state) => state.errorMessage,
    errorValidation: (state) => state.errorValidation,
    appVersion: (state) => state.appVersion,
  },

  mutations: {
    [types.ERROR](state, payload) {
      if (payload) {
        state.errorMessage = payload.msg || null;
        state.errorValidation = payload.errors || null;
      } else {
        state.errorMessage = payload || null;
      }
    },
  },
});
