/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import store from '@/store';
import * as types from '@/store/mutation-types';
import { differenceInMinutes, parseISO } from 'date-fns';
import sanitizeHtml from 'sanitize-html';

export const handleError = (error) => {
  let err;
  if (error.response) {
    let errors = null;
    const msg = error.response.data.message || error.response.statusText || `Error ${error.response.status}`;
    const { status } = error.response;

    if (status === 401) {
      // unauthenticated
      store.dispatch('userLogout');
    }

    if (status === 422) {
      // validations error
      errors = error.response.data.errors;
    }

    err = { msg, errors };
  } else {
    err = {
      msg: 'CONNECTION ERROR',
      errors: null,
    };
  }

  store.commit(types.ERROR, err);
};

export const handleDetailError = (error, vue) => {
  if (error.response.status === 404) {
    vue.$toast.warning(`${vue.$route.name} Not Found`);
    const parentRoute = vue.$route.matched[vue.$route.matched.length - 2];
    vue.$router.push(parentRoute.path);
  } else {
    vue.$toast.error(`Failed Get ${vue.$route.name}`);
  }
};

export const camelToSentence = (camelCaseText) => camelCaseText
  // insert a space before all caps
  .replace(/([A-Z])/g, ' $1');

export const nameCapitalized = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const underscoreToSpace = (text) => (text || '').replaceAll('_', ' ');

// check if object is empty
export function isEmptyObject(obj) {
  if (typeof obj !== 'object') return false;
  return Object.keys(obj).length === 0;
}

export function checkDeviceConnection(datetimeStr) {
  if (typeof datetimeStr === 'string') {
    let diffInMinutes;
    if (datetimeStr) {
      diffInMinutes = differenceInMinutes(new Date(), parseISO(datetimeStr));
    } else {
      return false;
    }
    // if latest payload more than 60 minutes from now
    // asuumed as offline
    if (diffInMinutes > 60) {
      return false;
    }
    return true;
  }
  return null;
}

export function floatPrecision(num, precision = 2) {
  if (!num) return num;
  const parsed = parseFloat(num);
  // check if payload float, then convert to precision float
  return parsed.toString().indexOf('.') !== -1 ? parsed.toFixed(precision) : parsed;
}

export function highlightString(str, keyword) {
  if (!str) return null;
  const sanitizedInput = sanitizeHtml(str);
  const rex = new RegExp(keyword, 'gi');
  return sanitizedInput.replace(rex, '<span class="orange--text text--darken-2">$&</span>');
}

export function reorderFieldPayload(arr, arrValue, pushType) {
  if (arr.includes(arrValue)) {
    const moved = arr.splice(arr.findIndex((val) => val === arrValue), 1);
    if (pushType === 'front') {
      arr.unshift(moved[0]);
    } else if (pushType === 'back') {
      arr.push(moved[0]);
    }
  }
  return arr;
}
